/**
 * Direttiva che crea il footer applicativo
 */
angular.module('app').directive("footer", ($window) => {
	return {
		link: link,
		replace: true,
		restrict: 'AE',
		templateUrl: 'app/shared/footer/footer.html'
	};
	// Quando la larghezza della finestra è maggiore di 600px pulsante del menu si nasconde e, se è rimasto aperto, rimane l'icona Close. Quindi dopo i 600px reimposto l'icona di default
	function link(scope: any, element: any, attrs: any) {
		// Appena il footer si apre, eseguo uno scroll automatico della pagina fino al fondo
		scope.$watch(() => {
			return angular.element("#footer").is(':visible')
		}, () => {
			let footer = angular.element(document.querySelector('#footer'));
			// Poichè il form è costruito dinamicamente con un ng-if, devo assicurarmi che l'animazione non venga eseguita prima della sua creazione
			if (footer && footer[0]) {
				$('html, body').animate({
					scrollTop: $("#footer").offset().top
				}, 1000);
			}
		});
		scope.isFooterOpened = false;
		// Apre/chiude il footer
		scope.switchFooter = () => {
			scope.isFooterOpened = !scope.isFooterOpened;
		}
		scope.openFooterPdf = () => {
			window.open('/assets/img/processo_apertura_ticket_GLP.pdf');
		  }
	}
});